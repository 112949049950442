import { FaKeyboard } from "react-icons/fa6";
import { CgWebsite } from "react-icons/cg";
import { RiToolsLine } from "react-icons/ri";
import { RiPsychotherapyFill } from "react-icons/ri";

const data = [
    {
        id: 1, icon: <FaKeyboard />, title: 'skill_langs'
    },
    {
        id: 2, icon: <CgWebsite />, title: 'skill_web'
    },
    {
        id: 3, icon: <RiToolsLine />, title: 'skill_tool'
    },
    {
        id: 4, icon: <RiPsychotherapyFill />, title: 'skill_other'
    }
]


export default data