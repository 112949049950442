import Image1 from '../../assets/mini-amazon.jpg'
import Image2 from '../../assets/DukeConnect.jpeg'
import Image3 from '../../assets/my-personal-page.jpg'
import Image4 from '../../assets/ride_sharing_web_app.jpg'
import Image5 from '../../assets/http_proxy_server.jpg'
import Image6 from '../../assets/img.jpg'
import Image7 from '../../assets/img.jpg'
import Image8 from '../../assets/img.jpg'
import Image9 from '../../assets/img.jpg'

const data = [
    {
        id: 1,
        category: 'full_stack',
        image: Image1,
        title: "mini_amazon",
        demo: 'https://www.youtube.com/watch?v=rV_J4431dYQ',
        github: 'https://github.com/Oli-Zhou/mini-amazon.git'
    },
    {
        id: 2,
        category: 'full_stack',
        image: Image2,
        title: "duke_connect_app",
        demo: 'https://www.youtube.com/watch?v=0NJecoSNP1Y',
        github: 'https://github.com/Oli-Zhou/duke-connect-app'
    },
    {
        id: 3,
        category: 'front_end',
        image: Image3,
        title: "my_personal_page",
        demo: 'https://aoliportfolio.com/',
        github: 'https://github.com/Oli-Zhou/MyPortofolioPage'
    },
    {
        id: 4,
        category: 'full_stack',
        image: Image4,
        title: "ride_sharing_web_app",
        demo: '',
        github: 'https://github.com/Oli-Zhou/ride-sharing-web-app'
    },
    {
        id: 4,
        category: 'back_end',
        image: Image5,
        title: "http_proxy_server",
        demo: '',
        github: 'https://github.com/Oli-Zhou/http-proxy-server'
    }
    // {
    //     id: 5,
    //     category: 'uiux',
    //     image: Image5,
    //     title: "Project Title Five (UIUX)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut! ",
    //     demo: 'http://egatortutorials.com',
    //     github: 'https://github.com/egattor'
    // }
    // {
    //     id: 6,
    //     category: 'frontend',
    //     image: Image6,
    //     title: "Project Title Six (Frontend)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    //     demo: 'http://egatortutorials.com',
    //     github: 'https://github.com/egattor'
    // },
    // {
    //     id: 7,
    //     category: 'frontend',
    //     image: Image7,
    //     title: "Project Title Seven (Frontend)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    //     demo: 'http://egatortutorials.com',
    //     github: 'https://github.com/egattor'
    // },
    // {
    //     id: 8,
    //     category: 'backend',
    //     image: Image8,
    //     title: "Project Title Eight (Backend)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    //     demo: 'http://egatortutorials.com',
    //     github: 'https://github.com/egattor'
    // },
    // {
    //     id: 9,
    //     category: 'frontend',
    //     image: Image9,
    //     title: "Project Title Nine (Frontend)",
    //     desc: "Provident similique accusantium nemo autem. Veritatis obcaecati tenetur iure eius earum ut molestias architecto voluptate aliquam nihil, eveniet aliquid culpa officia aut!",
    //     demo: 'http://egatortutorials.com',
    //     github: 'https://github.com/egattor'
    // }
]


export default data