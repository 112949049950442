import React from 'react'
import { useTranslation } from "react-i18next";

function CategoryButton({category, className, onChangeCategory}) {
  const { t } = useTranslation();
  return (
    <button className={className} onClick={() => onChangeCategory(category)}>{t(category)}</button>
  )
}

export default CategoryButton