import AboutImage from '../../assets/aboutme.JPG'
import CVen from '../../assets/cv_en.pdf'
import CVzh from '../../assets/cv_zh.pdf'
import { MdDownload } from "react-icons/md"
import Card from '../../components/Card'
import { education } from './data'
import { useTranslation } from "react-i18next";
import './about.css'

const About = () => {
  const { t } = useTranslation();
  const getCVLink = () => {
    return t('language_code') === 'en' ? CVen : CVzh;
  };

  return (
    <section id="about" data-aos="fade-in">
      {/* first 'container':  a CSS class named container defined in index.css */}
      {/* 'about__container': a custom CSS class here. The '__' notation typically indicates */}
      {/*  a CSS class used for styling a specific component or section of the page. */}
      <div className='container about__container'>
        <div className="about__left">
          <div className="about__img">
            <img src={AboutImage} alt="About Image" />
          </div>
        </div>
        <div className="about__right">
          <h2>{t('nav_about')}</h2>
          <div className="about__cards">
            {
              education.map(item => (
                <Card key={item.id} className="about__card">
                  {/* span:  inline container for phrasing content */}
                  <span className="about__card-icon">{item.icon}</span>
                  <h5>{t('title_'+item.title)}</h5>
                  <small>{t(item.title)}</small>
                </Card>
              ))
            }
          </div>
          <div dangerouslySetInnerHTML={{__html: t('about_intro.0')}} />
          <br></br>
          <div dangerouslySetInnerHTML={{__html: t('about_intro.1')}} />
          <br></br>
          <div dangerouslySetInnerHTML={{__html: t('about_intro.2')}} />
          <br></br>
          <p>{t('about_intro.3')}</p>
          <p>{t('about_intro.4')}</p>
          <a href={getCVLink()} download className='btn primary'>{t('download_cv')} <MdDownload/></a>
        </div>
      </div>
    </section>
  )
}

export default About