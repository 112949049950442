import contacts from './data'
import './contact.css'
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t } = useTranslation();

  return (
    <section id="contact">
      <h2>{t('nav_contact')}</h2>
      <p>
        {t('contact_desc')}
      </p>
      <div className="container contact__container" data-aos="fade-up">
        {
          //  target='_blank' open the linked URL in a new browser tab or window
          // noopener: navigate to the target resource without granting it access the doc that opened it
          // noreferrer: target resource cannot know if the originating doc is accessing it
          contacts.map(contact => <a key={contact.id} href={contact.link} target='_blank' rel='noopener noreferrer'>{contact.icon}</a>)
        }
      </div>
    </section>
  )
}

export default Contact