// This is for computer browsers
import Logo from '../../assets/img.jpg'
import data from './data'
import { useTranslation } from "react-i18next";
// import 'bootstrap/dist/css/bootstrap.min.css'
import './navbar.css'

import LangSelector from './LangSelector';


const Navbar = () => {
  const { t } = useTranslation();
  
  return (
    <nav>
      <div className="container nav__container">
        <a href="index.html" className='nav__Logo'>
          <img src={Logo} alt="Logo" />
        </a>
        <ul className='nav__menu'>
          {
            // Each list item (<li>) inside the <ul> represents a separate menu item
            data.map(item => <li key={item.id}><a href={item.link}>{t(item.title)}</a></li>)
            //data.map(item => <a key={item.id} href={item.link}>{item.title}</a>)
          }
        </ul>

        <LangSelector/>
        
        {/* <button id='theme__icon'><MdLanguage /></button> */}
      </div>
    </nav>
  )
}

export default Navbar