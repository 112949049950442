import './selector.css'
import { MdLanguage } from "react-icons/md";
import i18next from 'i18next'
import cookies from 'js-cookie'
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function LangSelector() {
  const languages = [
    {
      code: 'en',
      name: "English"
    },
    {
      code: 'zh',
      name: "简体中文"
    }
  ];

  const currentLanguageCode = cookies.get('i18next') || 'en'
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode)
  const { t } = useTranslation()


  useEffect(() => {
    document.title = t('app_title')
  }, [currentLanguage, t])

  return (
    <div class="dropdown">
    <button class="btn selector dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false"><MdLanguage /></button>
    <ul class="dropdown-menu">
      {
        languages.map(({code, name}) => (
          <li key={code}>
            <button class="dropdown-item" onClick={() => i18next.changeLanguage(code)}><a href={'https://aoliportfolio.com/'+ code}>{name}</a></button>
          </li>
        ))
      }
      
    </ul>
  </div>
  )
}

export default LangSelector