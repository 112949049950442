// defines a React component called Card. accepts three props
// You can use it <Card className="" onClick={handleCardClick}> </Card> after imported
// children means the content between the <Card>here!</Card>

import './card.css'

// className: additional CSS classes to be passed to the Card component.
// onClick: allows an event handler function to be passed to the Card component,  will be triggered when the card is clicked.
const Card = ({children, className, onClick}) => {
  //JSX code returned by the Card componen
    return (
        // The class name card comes from the imported CSS file (card.css).
        // allows additional CSS classes to be added dynamically
        // "I am " + stringA + "!" => `I am ${stringA}!`
        <article className={`card ${className}`} onClick={onClick}>
            {children}
        </article>
    )
}

// default keyword: Card is the default export of the file. When you import the Card component in another file, you don't need to use curly braces ({}) because it's the default export
// it means using import Card from './Card'; instead of import {Card} from './Card'; 
export default Card

