import Card from '../../components/Card'
import { useTranslation } from "react-i18next";

const Project = ({project}) => {
  const { t } = useTranslation();

  return (
    <Card className="portfolio__project">
        <div className="portfolio__project-image">
            <img src={project.image} alt="Portfolio Project Image" />
        </div>
        <h4>{t(project.title+'_title')+' ('+t(project.category)+')'}</h4>
        <p>{t(project.title+'_desc')}</p>
        <div className="portfolio__project-cta">
          {
            project.demo != '' ? (
            <a href={project.demo} className="btn sm" target="_blank" rel="noopener noreferrer">{t('portfolio_demo')}</a>
            ) : (
              <div className='nodemo'><span>{t('no_demo')}</span></div>
            )
          }
          <a href={project.github} className="btn sm primary" target="_blank" rel="noopener noreferrer">{t('portfolio_code')}</a>
        </div>
    </Card>
  )
}

export default Project