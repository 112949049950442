import HeaderImage from '../../assets/img.jpg'
import { socials } from './data'
import './header.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import { useTranslation } from "react-i18next";
import { useEffect } from 'react'

const Header = () => {
  useEffect(() => {
    AOS.init({duration: 2000})
  }, [])
  const { t } = useTranslation();
  return (
    <header id="header">
      <div className="container header__container">
        <div className="header__profile" data-aos="fade-in">
          <img src={HeaderImage} alt="Header Image" />
        </div>
        <h3 data-aos="flip-up">{t('name')}</h3>
        
        {/* <ul className='header_intro'>
          {
            t('header_intro').split('\n').map(item => <p data-aos="flip-up">{item}</p>)
          }
        </ul> */}
        <p data-aos="flip-up">{t('header_intro')}</p>
        <div className="header__cta" data-aos="fade-up">
          <a href="#contact" className='btn primary'>{t('header_talk')}</a>
          <a href="#portfolio" className='btn light'>{t('header_work')}</a>
        </div>
        <div className="header__socials">
          {
            //target='_blank' ensures that the links open in a new tab
            //rel="noopener noreferrer" is a security measure to prevent certain types of attacks
            socials.map(item => <a key={item.id} href={item.link} target='_blank' rel="noopener noreferrer">{item.icon}</a>)
          }
        </div>
      </div>
    </header>
  )
}

export default Header