import ProjectsCategories from './ProjectsCategories'
import Projects from './Projects'
import './portfolio.css'
import data from './data'
import { useState } from 'react'
import { useTranslation } from "react-i18next";
// a way to “preserve” some values between the function calls
// Normally, variables “disappear” when the function exits but state variables are preserved by React.

const Portfolio = () => {
  // useState accepts an initial state and returns two values: 
  // The current state. A function that updates the state.
  const [projects, setProjects] = useState(data);
  const categories = data.map(item => item.category);
  const uniqueCategories = ["all", ...new Set(categories)];
  const filterProjectsHandler = (category) => {
    if(category == "all") {
      setProjects(data);
      return;
    }
    const filterProjects = data.filter(project => project.category === category);
    setProjects(filterProjects);
  }

  const { t } = useTranslation();

  return (
    <section id="portfolio">
      <h2>{t('portfolio_title')}</h2>
      <p>{t('portfolio_desc')}</p>
      <div className="container portfolio__container">
        <ProjectsCategories categories={uniqueCategories} onFilterProjects={filterProjectsHandler}/>
        <Projects projects={projects}/>
      </div>
    </section>
  )
}

export default Portfolio