import data from'./data'
import Card from '../../components/Card'
import { useTranslation } from "react-i18next";
import './skills.css'

const Skills = () => {
  const { t } = useTranslation();

  return (
    <section id="skills" data-aos="fade-up">
      <h2>{t('skills_title')}</h2>
      <p></p>
      <div className="container services__container">
        {
          data.map(item => (
            <Card key={item.id} className="service light">
              <div className="service__icon">{item.icon}</div>
              <div className="service__details">
                <h4>{t(item.title+'_title')}</h4>
                <p>{t(item.title+'_desc')}</p>
              </div>
            </Card>
          ))
        }
      </div>
    </section>
  )
}

export default Skills